import { ClientEnvSchema } from '#/clientEnv'

import { type ServerEnv, ServerEnvSchema } from '../server/serverEnv'
import { getEnvStore } from './envStore'

type EvalFunctionProps<T> = {
  [K in keyof T]: T[K] extends () => unknown ? ReturnType<T[K]> : T[K]
}

function env<K extends keyof ServerEnv>(key: K): () => ServerEnv[K] {
  return () => {
    if (typeof window !== 'undefined' && !(key in ClientEnvSchema.shape)) {
      throw new Error(
        `Environment variable ${key.toString()} is not available in the browser.`,
      )
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value = ServerEnvSchema.pick({ [key]: true } as any).parse(
      getEnvStore(),
    )[key]

    return value
  }
}

function config<T>(object: T): EvalFunctionProps<T> {
  return new Proxy(
    {},
    {
      get: (_, prop) => {
        const value = object[prop as keyof T]

        if (typeof value === 'function') {
          return value.apply(object)
        }

        return value
      },
    },
  ) as EvalFunctionProps<T>
}

export { config, env, getEnvStore }
