import type { FlexProps } from '#/components/ui/Flex'
import { Flex } from '#/components/ui/Flex'
import { WelcomeHeading } from '#/components/WelcomeHeading/WelcomeHeading'

import styles from './Impressum.module.css'

export default function Impressum(props: FlexProps) {
  return (
    <Flex
      {...props}
      direction="column"
      class={styles.Impressum}
    >
      <WelcomeHeading>Impressum</WelcomeHeading>
      <div class={styles.Content}>
        <section class={styles.Section}>
          <h2>Angaben gemäß § 5 TMG</h2>
          <p>
            SazTunes GmbH
            <br />
            Kuhlertstr. 98
            <br />
            52525 Heinsberg
            <br />
            Deutschland
          </p>
        </section>

        <section class={styles.Section}>
          <h2>Vertreten durch</h2>
          <p>
            Hakan Eren
            <br />
            Jascha Dachtera
          </p>
        </section>

        <section class={styles.Section}>
          <h2>Handelsregister</h2>
          <p>
            Amtsgericht Aachen
            <br />
            Registernummer: HRB 27830
          </p>
        </section>

        <section class={styles.Section}>
          <h2>Kontakt</h2>
          <p>
            030 / 20614483
            <br />
            saztunesgmbh&nbsp;@&nbsp;gmail.com
            <br />
            saztunes.com
          </p>
        </section>

        <section class={styles.Section}>
          <h2>Umsatzsteuer-ID</h2>
          <p>USt-ID gemäß §27 a Umsatzsteuergesetz: DE368588570</p>
        </section>

        <section class={styles.Section}>
          <h2>Haftungsausschluss</h2>
          <p>
            Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine
            Haftung für die Inhalte externer Links. Für den Inhalt der
            verlinkten Seiten sind ausschließlich deren Betreiber
            verantwortlich.
          </p>
        </section>
      </div>
    </Flex>
  )
}
