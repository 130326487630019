import type { User } from '#/db/types/User'

const hasActiveSubscriptionUntil = (
  subscription:
    | {
        details?: Pick<
          NonNullable<NonNullable<User['subscription']>['details']>,
          'currentPeriodEndsAt' | 'currentPeriodStartsAt'
        >
      }
    | undefined,
) => {
  return subscription?.details &&
    subscription.details.currentPeriodStartsAt < new Date() &&
    subscription.details.currentPeriodEndsAt > new Date()
    ? subscription.details.currentPeriodEndsAt
    : undefined
}

export { hasActiveSubscriptionUntil }
