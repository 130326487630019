import { createTRPCProxyClient, httpLink } from '@trpc/client'
import SuperJSON from 'superjson'

import { getTrpcHeaders } from './getTrpcHeaders'
import type { AppRouter } from './server'

export const trpc = createTRPCProxyClient<AppRouter>({
  links: [
    httpLink({
      url: '/api/trpc',
      headers: getTrpcHeaders(),
    }),
  ],
  transformer: SuperJSON,
})
