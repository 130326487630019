import { authClient } from '../../authClient'
import { authLogger } from '../../authLogger'
import type { createResetState } from './createResetState'

export function createRequestLoginLink({
  setLoading,

  resetState,
}: {
  setLoading: () => void
  resetState: ReturnType<typeof createResetState>
}) {
  return async ({ email }: { email: string }) => {
    try {
      setLoading()
      await authClient.requestLoginLink.mutate({
        email,
      })

      authLogger.info('Successfully logged in with email and password')

      resetState()
    } catch (error) {
      authLogger.error(
        'Error while logging in with username and password',
        error,
      )
      resetState()
    }
  }
}
