import type { Accessor } from 'solid-js'
import type { SetStoreFunction, Store } from 'solid-js/store'

import { clientEnv } from '#/clientEnv'

import { createActionEventEmitter } from './createActionEventEmitter'
import { createMappedState } from './createMappedState'
import { createReduxDevtoolsStoreSync } from './createReduxDevtoolsSyncedStore'

const createModuleContextValue = <
  S extends object,
  M extends object,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  A extends Record<string, (...args: any[]) => unknown>,
>({
  createActions,
  store: [state, setState],
  mapState,
  getInitialState,
  name,
  log,
}: {
  store: [get: Store<S>, set: SetStoreFunction<S>]
  getInitialState: () => S
  mapState: (state: S) => Accessor<M>
  createActions: (mappedState: M, setState: SetStoreFunction<S>) => A
  name: string
  log?: typeof console.log
}) => {
  const resetState = () => setState(getInitialState())

  const mappedState = createMappedState(state, mapState)

  const storage = (
    typeof localStorage !== 'undefined' ? localStorage : null
  ) as Storage | null

  const usePatchedSetState =
    storage?.getItem('DEBUG') === '1'
      ? true
      : storage?.getItem('DEBUG') === '0'
        ? false
        : clientEnv.NODE_ENV !== 'production'

  const patchedSetState = usePatchedSetState
    ? createReduxDevtoolsStoreSync(mappedState, setState, name, log)
    : setState

  const actions = createActions(mappedState, patchedSetState)

  const { useEvent, useEventOnce, eventEmitter } = createActionEventEmitter<A>()

  Object.entries(actions).forEach(([key, action]) => {
    const patchedAction = (...args: unknown[]) => {
      const result = action(...args)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      eventEmitter.emit(key, ...(args as any))
      return result
    }

    Object.assign(actions, { [key]: patchedAction })
  })

  return [
    mappedState,
    actions,
    { useEvent, useEventOnce, resetState, getInitialState },
  ] as const
}

export { createModuleContextValue }
