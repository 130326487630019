import cookie from 'js-cookie'

import { decodeAccessToken } from '#/auth/util/decodeAccessToken'

import { accessTokenCookie, authCookiePaths } from '../../config'
import type { TokenStore } from './createTokenStore'

export function createHandleToken(setTokenState: TokenStore[1]) {
  return ({ accessToken }: { accessToken: string }) => {
    authCookiePaths.forEach((path) => {
      cookie.set(accessTokenCookie, accessToken, {
        domain: new URL(location.origin).hostname,
        path,
      })
    })

    const { expiresAt, issuedAt, roles } = decodeAccessToken(accessToken)

    setTokenState({
      accessToken,
      expiresAt,
      issuedAt,
      roles,
      isLoading: false,
      hasFinishedInitialLoading: true,
    })
  }
}
