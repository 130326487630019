import { type JSX } from 'solid-js'

import { Flex, type FlexProps } from '../ui/Flex'
import styles from './Form.module.css'

type FormProps = JSX.IntrinsicElements['form'] & FlexProps<'form'>

export const Form = (props: FormProps) => {
  return (
    <Flex
      {...props}
      gap={props.gap ?? 'md'}
      direction={props.direction ?? 'column'}
      margin={props.margin ?? 'md'}
      as="form"
      classList={{ [styles.Form!]: true, ...props.classList }}
    >
      {props.children}
    </Flex>
  )
}
