/* eslint-disable @typescript-eslint/no-empty-object-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
// https://stackoverflow.com/a/55128956

// oh boy don't do this
type UnionToIntersection<K> = (K extends any ? (k: K) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never
type LastOf<T> =
  UnionToIntersection<T extends any ? () => T : never> extends () => infer R
    ? R extends T
      ? R
      : never
    : never
type TuplifyUnion<T> = [T] extends [never]
  ? []
  : [...TuplifyUnion<Exclude<T, LastOf<T>>>, LastOf<T>]
type GeneralKeys<T> = [T] extends [never]
  ? never
  :
      | (keyof any extends infer A ? (A extends LastOf<T> ? T : never) : never)
      | GeneralKeys<Exclude<T, LastOf<T>>>
type SpecificKeys<T> = Exclude<T, GeneralKeys<T>>
type TuplifyKeys<T> = [
  ...TuplifyUnion<SpecificKeys<T>>,
  ...([any] extends [GeneralKeys<T>] ? GeneralKeys<T>[] : []),
]
type TuplifyArrayKeys<T> = TuplifyKeys<Exclude<keyof T, keyof []>>
type Entries<T extends {}> = T extends any
  ? (
      T extends [...any] ? TuplifyArrayKeys<T> : TuplifyKeys<keyof T>
    ) extends infer K
    ? { [I in keyof K]: [K[I], K[I] extends keyof T ? T[K[I]] : never] }
    : never
  : never

export const entries: <T extends {}>(obj: T) => Entries<T> =
  Object.entries as any

export const fromEntries: <T extends readonly [keyof any, any][]>(
  entries: T,
) => T extends T ? { [E in T[number] as E[0]]: E[1] } : never =
  Object.fromEntries
