import type { JSX } from 'solid-js'
import { createSignal, Show } from 'solid-js'

import { Form } from '#/components/form/Form'
import { TextField } from '#/components/form/TextField/TextField'
import { Button } from '#/components/ui/Button'
import { createTrpcMutation, createTrpcQuery } from '#/trpc/solidTrpc'

import Impressum from './static/impressum/Impressum'
import { Flex } from './ui/Flex'
import { WelcomeHeading } from './WelcomeHeading/WelcomeHeading'

export default function BetaAccessGuard(props: { children: JSX.Element }) {
  const [state, { refetch: refetchBetaAuthState }] = createTrpcQuery({
    query: 'getBetaAuthState',
    needsAuth: false,
  })

  const [username, setUsername] = createSignal<string>('')
  const [password, setPassword] = createSignal<string>('')

  const [{ mutate: setBetaAuthToken }] = createTrpcMutation({
    mutation: 'setBetaAuthToken',
    needsAuth: false,
  })

  const handleSubmit = async (event: Event) => {
    event.preventDefault()

    await setBetaAuthToken({
      username: username(),
      password: password(),
      origin: window.location.origin,
    })

    await refetchBetaAuthState()
  }

  return (
    <Show
      when={state()?.isValidToken}
      fallback={
        <Flex
          direction="column"
          hidden={!state() || state.loading}
        >
          <Form onSubmit={handleSubmit}>
            <WelcomeHeading>Beta Test Access</WelcomeHeading>
            <p>You need to enter a password to access this page.</p>
            <TextField
              label="Username"
              value={username()}
              onChange={(value) => setUsername(value)}
            />

            <TextField
              label="Password"
              value={password()}
              onChange={(value) => setPassword(value)}
              inputProps={{ type: 'password' }}
              name="password"
            />
            <Button type="submit">Submit</Button>
          </Form>
          <Impressum />
        </Flex>
      }
    >
      {props.children}
    </Show>
  )
}
