import './style.css'
import './variables.css'
import './typography.css'
import './colors.css'

import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen'
import { Capacitor } from '@capacitor/core'
import { SplashScreen } from '@capacitor/splash-screen'
import type { JSX } from 'solid-js'
import { createMemo, onMount, Show } from 'solid-js'
import { usePageContext } from 'vike-solid/usePageContext'

import { AuthGuard } from '#/auth/ui/AuthGuard'
import { AuthProvider } from '#/auth/ui/AuthProvider'
import BetaAccessGuard from '#/components/BetaAuthGuard'
import { DialogProvider } from '#/components/Dialog/Dialog'
import { I18nProvider } from '#/i18n/I18nProvider'
import { SubscriptionProvider } from '#/subscription/state/SubscriptionContext'
import { TrpcProvider } from '#/trpc/TrpcProvider'

import { createUrlListener } from './createUrlListener'
import { SentryWrapper } from './Sentry'

export default function LayoutDefault(props: { children?: JSX.Element }) {
  const requireAuth = createMemo(
    () => (usePageContext().config as { requireAuth: boolean }).requireAuth,
  )

  createUrlListener()

  onMount(async () => {
    try {
      if (Capacitor.getPlatform() === 'android') {
        const isImmersiveModeSupported =
          await AndroidFullScreen.isImmersiveModeSupported()

        if (isImmersiveModeSupported) {
          await AndroidFullScreen.immersiveMode()
        }
      }

      await SplashScreen.hide()
    } catch (error) {
      console.warn(error)
    }
  })

  return (
    <I18nProvider>
      <TrpcProvider>
        <AuthProvider>
          <DialogProvider>
            <SubscriptionProvider>
              <SentryWrapper>
                <Show
                  when={requireAuth()}
                  fallback={props.children}
                >
                  <BetaAccessGuard>
                    <AuthGuard>{props.children}</AuthGuard>
                  </BetaAccessGuard>
                </Show>
              </SentryWrapper>
            </SubscriptionProvider>
          </DialogProvider>
        </AuthProvider>
      </TrpcProvider>
    </I18nProvider>
  )
}
