import { z } from 'zod'

import { countInModes } from '#/components/Player/state/initialPlayerState'
import {
  PlaybackSpeed,
  resolutions,
  Tuning,
} from '#/lambdaFunctions/utils/formatConfig'
import { entries } from '#/utils/objectFromEntries'
import { zodLiteralUnion } from '#/utils/zodLiteralUnion'

export const UserSettingsSchema = z.object({
  player: z
    .object({
      isMirrored: z.boolean().optional(),
      metronome: z
        .object({
          isEnabled: z.boolean().optional(),
          volume: z.number().optional(),
          countIn: zodLiteralUnion(countInModes).optional(),
        })
        .optional(),
      preferredResolution: zodLiteralUnion(resolutions).optional(),
      audio: z.enum(['muted', 'melody']).optional(),
      showFingering: z.boolean().optional(),
      showFrets: z.boolean().optional(),
      tuning: zodLiteralUnion(entries(Tuning).map(([, v]) => v)).optional(),
      speed: zodLiteralUnion(entries(PlaybackSpeed).map(([k]) => k)).optional(),
      noteFormat: z
        .union([z.literal('CDE'), z.literal('DoReMi')] as const)
        .optional(),
      showNoteNames: z.boolean().optional(),
    })
    .optional(),
})

export type UserSettings = z.infer<typeof UserSettingsSchema>
