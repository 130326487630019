import { createStore } from 'solid-js/store'

import type { UserRole } from '#/db/types/User'

export type TokenStore = ReturnType<typeof createTokenStore>

export function createTokenStore() {
  const [getState, setState] = createStore<{
    hasFinishedInitialLoading: boolean
    isLoading: boolean
    accessToken?: string
    expiresAt?: Date
    issuedAt?: Date
    roles?: UserRole[]
  }>({ isLoading: true, hasFinishedInitialLoading: false })

  return [getState, setState] as const
}
