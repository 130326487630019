import { z } from 'zod'

import { UserRoleSchema } from '#/db/types/User'

import { createJwtTokenPayloadSchema } from './createJwtTokenPayloadSchema'

export const AccessTokenAudience = 'access_token'

export const AccessTokenPayloadSchema = createJwtTokenPayloadSchema(
  AccessTokenAudience,
  {
    subexp: z
      .number()
      .transform((timestamp) => new Date(timestamp * 1000))
      .optional(),
    roles: z.array(UserRoleSchema),
  },
).transform((data) => {
  const { roles, subexp: subscriptionExpiresAt, ...rest } = data

  return { subscriptionExpiresAt, roles, ...rest }
})

export type AccessTokenPayload = z.infer<typeof AccessTokenPayloadSchema>
