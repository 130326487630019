import type { JSX } from 'solid-js'
import { createContext, createSignal, type Signal, useContext } from 'solid-js'

const TrpcContext = createContext<{
  createStorage: {
    <T>(id: string): () => Signal<T | undefined>
  }
  reset: () => void
}>({
  createStorage: function <T>(): () => Signal<T | undefined> {
    throw new Error('createStorage must be used within a TrpcProvider')
  },
  reset: () => {},
})

export const TrpcProvider = (props: { children: JSX.Element }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let storages: Record<string, Signal<any>> = {}

  function createStorage<T>(id: string) {
    return (): Signal<T | undefined> => {
      if (!storages[id]) {
        storages[id] = createSignal<T>()
      }
      return storages[id]
    }
  }

  const reset = () => {
    storages = {}
  }

  return (
    <TrpcContext.Provider value={{ createStorage, reset }}>
      {props.children}
    </TrpcContext.Provider>
  )
}

export const useTrpcContext = () => {
  const context = useContext(TrpcContext)
  if (!context) {
    throw new Error('useTrpcGlobalStorage must be used within a TrpcProvider')
  }
  return context
}
