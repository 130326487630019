import type { TextFieldRootProps } from '@kobalte/core/text-field'
import { TextField as KobalteTextField } from '@kobalte/core/text-field'
import { type ComponentProps, For, splitProps } from 'solid-js'

import type { FlexProps } from '#/components/ui/Flex'
import { Flex } from '#/components/ui/Flex'

import { ErrorMessage } from '../ErrorMessage/ErrorMessage'
import styles from './TextField.module.css'

type FieldError = {
  message: string
  code?: string
}

type TextFieldProps = TextFieldRootProps<typeof Flex> & {
  label?: string
  description?: string
  errors?: FieldError[]
  inputProps?: ComponentProps<typeof KobalteTextField.Input<'input'>>
} & Omit<FlexProps<'div'>, keyof TextFieldRootProps<typeof Flex>>

export const TextField = (props: TextFieldProps) => {
  const [ownProps, rest] = splitProps(props, [
    'label',
    'description',
    'errors',
    'inputProps',
  ])

  return (
    <KobalteTextField
      {...rest}
      direction="column"
      as={Flex}
      classList={{ [styles.TextFieldRoot!]: true }}
      validationState={ownProps.errors?.length ? 'invalid' : 'valid'}
    >
      <div class={styles.TextFieldWrapper}>
        <KobalteTextField.Input
          {...ownProps.inputProps}
          placeholder={ownProps.label}
          class={styles.TextFieldInput}
        />
        <KobalteTextField.Label
          hidden={!ownProps.label}
          class={styles.TextFieldLabel}
        >
          {props.label}
        </KobalteTextField.Label>
      </div>
      <KobalteTextField.Description
        hidden={!ownProps.description}
        class={styles.TextFieldDescription}
      >
        {props.description}
      </KobalteTextField.Description>

      <For each={ownProps.errors}>
        {(error) => (
          <KobalteTextField.ErrorMessage as={ErrorMessage}>
            {error.message}
          </KobalteTextField.ErrorMessage>
        )}
      </For>
    </KobalteTextField>
  )
}
