/* eslint-disable @typescript-eslint/no-explicit-any */

import { type SetStoreFunction } from 'solid-js/store'

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P]
}

//
function enhanceSetState<T extends object = object>(
  setState: SetStoreFunction<T>,
  handler: (normalizedUpdate: DeepPartial<T>) => void,
): SetStoreFunction<T> {
  const untypedSetState = setState as any

  const enhancedSetState: SetStoreFunction<T> = (...args: unknown[]) => {
    const path = (args.length > 1 ? args.slice(0, -1) : []) as string[]
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    const lastArg = args[args.length - 1] as object | Function

    const callHandler = (update: unknown) => {
      const normalizedUpdate = (path.length ? {} : update) as Record<
        string,
        unknown
      >

      if (path.length) {
        let currentLevel = normalizedUpdate

        for (let i = 0; i < path.length - 2; i += 1) {
          currentLevel[path[i]!] = {}
          currentLevel = currentLevel[path[i]!] as Record<string, unknown>
        }

        currentLevel[path[path.length - 1]!] = update
      }

      handler(normalizedUpdate as DeepPartial<T>)
    }

    if (typeof lastArg === 'function') {
      untypedSetState(...path, (prevState: any) => {
        const newState: object = lastArg(prevState)
        callHandler(newState)

        return newState
      })
    } else {
      untypedSetState(...args.slice(0, -1), lastArg)

      callHandler(lastArg)
    }
  }

  return enhancedSetState
}

export { enhanceSetState }
