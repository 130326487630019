import { Button as KButton } from '@kobalte/core'
import type { ComponentProps } from 'solid-js'
import type { JSX } from 'solid-js'
import { Show, splitProps } from 'solid-js'

import styles from './Button.module.css'

const Button = (
  props: ComponentProps<typeof KButton.Root> & {
    variant?: 'primary' | 'secondary' | 'danger' | 'ghost' | 'link'
  } & (
      | {
          isToggle: true
          isActive?: boolean
        }
      | {
          isToggle?: false
          isActive?: never
        }
    ) & {
      icon?: JSX.Element
    },
) => {
  const [ownProps, rest] = splitProps(props, [
    'isToggle',
    'isActive',
    'classList',
    'variant',
    'icon',
    'children',
  ])

  return (
    <KButton.Root
      {...rest}
      data-is-active={ownProps.isToggle && ownProps.isActive ? '' : undefined}
      classList={{
        [styles.Button!]: true,
        ...ownProps.classList,
        [styles.ButtonPrimary!]:
          !ownProps.variant || ownProps.variant === 'primary',
        [styles.ButtonSecondary!]: ownProps.variant === 'secondary',
        [styles.ButtonDanger!]: ownProps.variant === 'danger',
        [styles.ButtonGhost!]: ownProps.variant === 'ghost',
        [styles.ButtonLink!]: ownProps.variant === 'link',
      }}
    >
      <Show when={ownProps.icon}>
        <div class={styles.Icon}>{ownProps.icon}</div>
      </Show>
      {ownProps.children}
    </KButton.Root>
  )
}

export { Button }
