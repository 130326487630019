import { z } from 'zod'

import { BaseSchema } from './BaseSchema'
import { UserSettingsSchema } from './UserSettings'
import { UserSubscriptionSchema } from './UserSubscription'

export const UserRoleSchema = z.union([z.literal('ADMIN'), z.literal('USER')])

export type UserRole = z.infer<typeof UserRoleSchema>

export const UserSchema = BaseSchema.extend({
  email: z.string().email().optional(),
  givenName: z.string().min(2).optional(),
  familyName: z.string().min(2).optional(),
  appleId: z.string().optional(),
  googleId: z.string().optional(),
  password: z.string().optional(),
  roles: z.array(UserRoleSchema).default(['USER']),
  subscription: UserSubscriptionSchema.optional(),
  settings: UserSettingsSchema.optional(),
  isEmailVerified: z.boolean().optional(),
})

export type User = z.infer<typeof UserSchema>
