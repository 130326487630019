import { createSignal, Show } from 'solid-js'

import { Form } from '#/components/form/Form'
import { TextField } from '#/components/form/TextField/TextField'
import { Button } from '#/components/ui/Button'
import { Flex } from '#/components/ui/Flex'
import { WelcomeHeading } from '#/components/WelcomeHeading/WelcomeHeading'
import { t } from '#/i18n/I18nProvider'

import { useAuth } from './AuthProvider'
import styles from './LoginForm.module.css'
import { SignInWithAppleButton } from './SignInWithAppleButton'
import { SignInWithGoogleButton } from './SignInWithGoogleButton'

const LoginForm = () => {
  const auth = useAuth()

  const [mode, setMode] = createSignal<'login' | 'register'>()

  return (
    <>
      <WelcomeHeading>{t('auth.welcome')}</WelcomeHeading>
      <Form
        class={styles.LoginForm}
        onSubmit={(event) => {
          event.preventDefault()
          event.stopPropagation()

          const buttonValue =
            event.submitter instanceof HTMLButtonElement
              ? event.submitter.value
              : ''

          const formData = new FormData(event.currentTarget)

          switch (buttonValue) {
            case 'login':
              auth.loginWithEmailAndPassword({
                email: formData.get('email')?.toString() ?? '',
                password: formData.get('password')?.toString() ?? '',
              })
              break

            case 'register':
              auth.registerWithEmailAndPassword({
                email: formData.get('email')?.toString() ?? '',
                givenName: formData.get('givenName')?.toString() ?? '',
                familyName: formData.get('familyName')?.toString() ?? '',
                password: formData.get('password')?.toString() ?? '',
              })
              break

            case 'loginLink':
              auth.requestLoginLink({
                email: formData.get('email')?.toString() ?? '',
              })
              break
          }
          return false
        }}
      >
        <Show when={!mode()}>
          <SignInWithAppleButton />
          <SignInWithGoogleButton />
          <Flex class={styles.SocialSignInSeparator}>{t('auth.or')}</Flex>

          <div class={styles.FormSwitcher}>
            <Button
              variant="link"
              onClick={() => setMode('register')}
            >
              {t('auth.register')}
            </Button>{' '}
            {t('auth.or')}{' '}
            <Button
              variant="link"
              onClick={() => setMode('login')}
            >
              {t('auth.login')}
            </Button>{' '}
            {t('auth.withEmailAndPassword')}
          </div>
        </Show>

        <Show when={mode()}>
          <TextField
            label={t('auth.email')}
            inputProps={{
              name: 'email',
              autocomplete: 'email',
            }}
          />

          <Show when={mode() === 'register'}>
            <TextField
              label={t('auth.givenName')}
              inputProps={{
                name: 'givenName',
                autocomplete: 'given-name',
              }}
            />

            <TextField
              label={t('auth.familyName')}
              inputProps={{
                name: 'familyName',
                autocomplete: 'family-name',
              }}
            />
          </Show>

          <TextField
            label={t('auth.password')}
            inputProps={{
              name: 'password',
              type: 'password',
              autocomplete: 'current-password new-password',
            }}
          />

          <Button
            type="submit"
            name="mode"
            value={mode()}
          >
            {mode() === 'login' ? t('auth.login') : t('auth.register')}
          </Button>

          <Show when={mode() === 'login'}>
            <Button
              type="submit"
              name="mode"
              value="loginLink"
            >
              {t('auth.sendLoginLink')}
            </Button>
          </Show>

          <div class={styles.FormSwitcher}>
            <Show when={mode() === 'login'}>
              {t('auth.noAccountYet')}{' '}
              <Button
                variant="link"
                onClick={() => setMode('register')}
              >
                {t('auth.register')}
              </Button>
            </Show>

            <Show when={mode() === 'register'}>
              {t('auth.alreadyHaveAnAccount')}{' '}
              <Button
                variant="link"
                onClick={() => setMode('login')}
              >
                {t('auth.login')}
              </Button>
            </Show>
          </div>

          <Flex class={styles.SocialSignInSeparator}>{t('auth.or')}</Flex>

          <Button
            variant="link"
            onClick={() => setMode(undefined)}
          >
            {t('auth.useSocialLogin')}
          </Button>
        </Show>
      </Form>
    </>
  )
}

export { LoginForm }
