import { config, env } from '#/utils/env'

export const apple = config({
  clientId: 'com.saztunes.applesignin',
  alg: 'ES256',
  keyId: env('AUTH_APPLE_KEY_ID'),
  teamId: env('AUTH_APPLE_TEAM_ID'),
  pkcs8: env('AUTH_APPLE_PKCS8'),
})

export const google = {
  clientId: {
    android:
      '995576627900-t9979r2b1g5auj2bm3807srlbadbo3am.apps.googleusercontent.com',
    web: '995576627900-3cfl1jo1vnp9taeh2fn9sg1bojsqesas.apps.googleusercontent.com',
    ios: '995576627900-6d0i237fbq385iuueegbq3q4vf19ehe1.apps.googleusercontent.com',
  },
}

export const token = config({
  alg: 'ES256',
  pkcs8: env('AUTH_TOKEN_PKCS8'),
  issuer: () => env('AUTH_TOKEN_ISSUER')() ?? env('SITE_URL')(),
})

export const authCookiePaths = ['/public', '/api/trpc', '/uppy'] as const

export const accessTokenCookie = 'accessToken'
