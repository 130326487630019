import { useAuth } from '#/auth/ui/AuthProvider'
import { useSubscriptionState } from '#/subscription/state/SubscriptionContext'

import { trpc } from './client'
import { createSolidTrpc } from './createSolidTrpc'
import type { AppRouter } from './server'

export const { solidTrpc, createTrpcMutation, createTrpcQuery } =
  createSolidTrpc<
    AppRouter,
    {
      needsAuth?: boolean
      needsSubscription?: boolean
    }
  >(trpc, {
    preprocess: async (args) => {
      const { needsAuth, needsSubscription } = args

      const auth = useAuth()
      const subscriptionState = useSubscriptionState()
      const hasActiveSubscriptionOrDoesntNeedSubscription =
        needsSubscription !== true || subscriptionState.hasActiveSubscription

      if (needsAuth !== false) await auth.waitForAuthentication()
      if (!hasActiveSubscriptionOrDoesntNeedSubscription) {
        throw new Error('Not authorized')
      }
    },
  })
