import { Capacitor } from '@capacitor/core'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'

import { google as googleConfig } from '#/auth/config'
import { getPlatform } from '#/utils/getPlatform'

import { authClient } from '../../authClient'
import { authLogger } from '../../authLogger'
import type { createHandleToken } from './createHandleToken'
import type { createResetState } from './createResetState'

export function createSignInWithGoogle({
  setLoading,
  handleToken,
  resetState,
}: {
  handleToken: ReturnType<typeof createHandleToken>
  setLoading: () => void
  resetState: ReturnType<typeof createResetState>
}) {
  if (!Capacitor.isNativePlatform()) {
    initializeGoogleAuth()
  }

  return async () => {
    try {
      await initializeGoogleAuth()

      await GoogleAuth.signOut()
      setLoading()
      const result = await GoogleAuth.signIn()

      const {
        authentication: { idToken },
        familyName,
        givenName,
        email,
      } = result

      const { accessToken } = await authClient.loginWithGoogle.mutate({
        idToken,
        familyName: familyName || undefined,
        givenName: givenName || undefined,
        email: email || undefined,
        platform: getPlatform(),
      })

      authLogger.info('Successfully logged in with Google', result)

      handleToken({ accessToken })
    } catch (error) {
      authLogger.error('Error while logging in with Google', error)
      resetState()
    }
  }
}

let initializePromise: Promise<void> | undefined

const initializeGoogleAuth = () => {
  if (!initializePromise) {
    initializePromise = GoogleAuth.initialize({
      clientId:
        googleConfig.clientId[
          getPlatform() === 'android' ? 'web' : getPlatform()
        ],
      scopes: ['profile', 'email'],
    })
  }
  return initializePromise
}
