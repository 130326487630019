import * as i18n from '@solid-primitives/i18n'
import type { JSX } from 'solid-js'
import { createContext, createMemo, createResource, useContext } from 'solid-js'
import { usePageContext } from 'vike-solid/usePageContext'

import { extractLanguageFromPathname } from './extractLanguageFromPathname'
import type { Dictionary } from './fetchDictionary'
import { fetchDictionary } from './fetchDictionary'
import { isValidlangugage } from './isValidlangugage'
import type { Language } from './languages'

const createGetLanguage = () => {
  const pageContext = usePageContext()

  const getLanguage = createMemo(() => {
    const { language } = extractLanguageFromPathname(pageContext.urlOriginal)

    return language ?? 'en'
  })

  return getLanguage
}

export const I18nContext = createContext<{
  t: i18n.NullableTranslator<Dictionary, string>
  language: Language
  pathnameWithLanguage: (args: {
    pathname?: string
    language?: Language
    replaceLanguage?: boolean
  }) => string
}>()

export const I18nProvider = (props: { children: JSX.Element }) => {
  const getLanguage = createGetLanguage()

  const [dictionary] = createResource(
    () => getLanguage(),
    async (language) => {
      return fetchDictionary(language)
    },
  )

  const pageContext = usePageContext()

  const removeTrailingSlash = (pathname: string) => {
    return pathname.replace(/\/$/, '')
  }

  const pathnameWithLanguage = ({
    pathname = pageContext.urlPathname,
    language = getLanguage(),
    replaceLanguage = false,
  }: {
    pathname?: string
    language?: Language
    replaceLanguage?: boolean
  } = {}) => {
    const currentLanguage = pathname.split('/')[1]

    if (!isValidlangugage(currentLanguage)) {
      return removeTrailingSlash(`/${language}${pathname}`)
    }

    if (replaceLanguage) {
      return removeTrailingSlash(
        `/${language}${pathname.split('/').slice(2).join('/')}`,
      )
    }

    return removeTrailingSlash(pathname)
  }

  const t = i18n.translator(dictionary, i18n.resolveTemplate)

  return (
    <I18nContext.Provider
      value={{ t, language: getLanguage(), pathnameWithLanguage }}
    >
      {props.children}
    </I18nContext.Provider>
  )
}

export const useI18n = () => {
  const context = useContext(I18nContext)
  if (!context) {
    throw new Error('useI18n must be used within an I18nProvider')
  }
  return context
}

export const t: i18n.NullableTranslator<Dictionary, string> = (
  path,
  ...args
) => {
  const { t } = useI18n()
  return t(path, ...args)
}
