import { extractLanguageFromPathname } from '#/i18n/extractLanguageFromPathname'

export const getTrpcHeaders = () => {
  if (typeof window === 'undefined') {
    return {}
  }

  const { language } = extractLanguageFromPathname(location.pathname)

  if (language) {
    return { 'Accept-Language': language }
  }
  return {}
}
