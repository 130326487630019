import { Capacitor } from '@capacitor/core'
import type { SignInWithAppleOptions } from '@capacitor-community/apple-sign-in'
import { SignInWithApple } from '@capacitor-community/apple-sign-in'

import { clientEnv } from '#/clientEnv'
import { getPlatform } from '#/utils/getPlatform'

import { authClient } from '../../authClient'
import { authLogger } from '../../authLogger'
import type { createHandleToken } from './createHandleToken'
import type { createResetState } from './createResetState'

export function createSignInWithApple({
  setLoading,
  handleToken,
  resetState,
}: {
  handleToken: ReturnType<typeof createHandleToken>
  setLoading: () => void
  resetState: ReturnType<typeof createResetState>
}) {
  return async () => {
    const clientId =
      getPlatform() === 'ios' ? 'com.saztunes.app' : 'com.saztunes.applesignin'

    const options: SignInWithAppleOptions = {
      clientId,
      redirectURI: `${location.origin}/auth/apple/return`,
      scopes: 'email name',
      state: JSON.stringify({
        platform: Capacitor.getPlatform(),
        environment: clientEnv.STACK_NAME,
      }),
      nonce: 'nonce',
    }

    try {
      const result = await SignInWithApple.authorize(options)
      authLogger.info('Sign In With Apple Response:', result.response)

      const { authorizationCode, familyName, givenName, email } =
        result.response

      setLoading()

      const { accessToken } = await authClient.loginWithAppleCode.mutate({
        clientId,
        authorizationCode,
        familyName: familyName || undefined,
        givenName: givenName || undefined,
        email: email || undefined,
      })

      authLogger.info('Successfully logged in with apple', result.response)

      handleToken({ accessToken })
    } catch (error) {
      authLogger.error('Error while logging in with apple', error)
      resetState()
    }
  }
}
