import type {
  PlaybackSpeedName,
  Tuning,
} from '#/lambdaFunctions/utils/formatConfig'
import { type Resolution } from '#/lambdaFunctions/utils/formatConfig'
import type { getNoteName } from '#/utils/noteNames'

import type { TransformedSong } from '../../../utils/notes/transformSong'

export type Step = TransformedSong['steps'][number]

export type CountInMode = (typeof countInModes)[number]
export type InitialPlayerState = typeof initialPlayerState

export const countInModes = ['start', 'always', 'never'] as const

export const initialPlayerState = {
  canPlay: false,

  currentPosition: {
    easeTime: 0,
    stepIndex: 0,
    time: 0,
    timeAtStart: 0,
  },

  metronome: {
    countIn: 'start' as CountInMode,
    isEnabled: true,
  },

  countIn: {
    total: 4 as number,
    current: undefined as number | undefined,
  },

  isDraggingLoop: false,
  isDraggingSheet: false,
  isEasing: false,
  isMirrored: false,
  isVideoPlaying: false,

  showNoteNames: false,
  showFingering: false,
  showFrets: false,

  sheetZoom: 1,

  audio: 'melody' as 'muted' | 'melody',

  isPlaying: false,

  loadingProgress: 0,

  focus: 'overview' as 'overview' | 'fretting' | 'strumming',

  lensPosition: {
    x: 0.12,
    y: 0.52,
  },

  loop: {
    isLooping: false,
    loopEndIndex: 0,
    loopStartIndex: 0,
  },

  playbackSpeed: 'full' as PlaybackSpeedName,
  tuning: 0 as Tuning,
  noteFormat: 'DoReMi' as Parameters<typeof getNoteName>[1],

  sheetWidth: 0,
  showLens: false,
  songId: '',

  preferredResolution: undefined as Resolution | undefined,
  videoElement: undefined as HTMLVideoElement | undefined,
}
