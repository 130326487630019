import * as Sentry from '@sentry/solid'
import { createEffect, type JSX } from 'solid-js'

import { useAuth } from '#/auth/ui/AuthProvider'
import { createTrpcQuery } from '#/trpc/solidTrpc'

export const SentryWrapper = (props: { children?: JSX.Element }) => {
  const [userInfo] = createTrpcQuery({
    query: 'getUserInfo',
    globalId: 'userInfo',
  })

  createEffect(() => {})

  const auth = useAuth()

  createEffect(() => {
    if (auth.isLoggedIn) {
      Sentry.setUser({
        id: userInfo()?._id,
      })
    } else {
      Sentry.setUser(null)
    }
  })

  return <>{props.children}</>
}
