import type { URLOpenListenerEvent } from '@capacitor/app'
import { App } from '@capacitor/app'
import { onMount } from 'solid-js'
import { redirect } from 'vike/abort'
import { modifyUrl } from 'vike/modifyUrl'

export const createUrlListener = () => {
  onMount(() => {
    if (typeof window === 'undefined') {
      return
    }

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const slug = getUrlWithoutHost(event.url)

      const currentSlug = getUrlWithoutHost(location.href)

      if (slug && slug !== currentSlug) {
        throw redirect(slug)
      }
    })
  })
}

const getUrlWithoutHost = (url: string) => {
  return modifyUrl(url, {
    hostname: 'saztunes.com',
    protocol: 'https',
    port: 3000,
  })
    .split('saztunes.com:3000')
    .pop()
}
