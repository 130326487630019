export const flattenedObjectKeys = (object: object) => {
  const keys: string[] = []

  const flatten = (obj: object, path: string[] = []) => {
    Object.entries(obj).forEach(([key, value]) => {
      if (
        typeof value === 'object' &&
        value !== null &&
        value?.constructor === Object
      ) {
        flatten(value, [...path, key])
      } else {
        keys.push([...path, key].join('.'))
      }
    })
  }

  flatten(object)

  return keys
}

export const commonObjectPathAndValue = (object: object) => {
  const paths = flattenedObjectKeys(object)

  const pathSegments = paths.map((key) => key.split('.'))
  const commonDepth = Math.min(...pathSegments.map((segment) => segment.length))
  const commonPath = Array.from({ length: commonDepth }).reduce<string[]>(
    (acc, _, index) => {
      const segment = pathSegments[0]![index]!
      if (pathSegments.every((path) => path[index] === segment)) {
        return [...acc, segment] as const
      }
      return acc
    },
    [],
  )

  const commonPathString = commonPath.join('.')

  const commonPathValue = commonPath.reduce(
    (acc, key) => acc[key],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    object as any,
  )

  return { commonPathString, commonPathValue }
}
