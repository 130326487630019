import { z } from 'zod'

import { zodObjectId } from './zodObjectId'

export type Base = z.infer<typeof BaseSchema>

export const BaseSchema = z.object({
  _id: zodObjectId(),
  createdAt: z.date(),
  updatedAt: z.date(),
})
