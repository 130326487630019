import { type JSX, Show } from 'solid-js'

import { Flex } from '#/components/ui/Flex'
import { Spinner } from '#/components/ui/Spinner'

import styles from './AuthGuard.module.css'
import { useAuth } from './AuthProvider'
import { LoginForm } from './LoginForm'

const AuthGuard = (props: { children: JSX.Element }): JSX.Element => {
  const auth = useAuth()

  return (
    <>
      <Show when={!auth.isLoggedIn && auth.hasFinishedInitialLoading}>
        <Flex
          class={styles.AuthGuard}
          direction="column"
        >
          <LoginForm />
        </Flex>
      </Show>

      <Show when={auth.isLoggedIn}>{props.children}</Show>

      <Show when={auth.isLoading}>
        <div class={styles.LoadingOverlay}>
          <Spinner centered />
        </div>
      </Show>
    </>
  )
}

export { AuthGuard }
