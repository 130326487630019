import { TRPCClientError } from '@trpc/client'
import { type CreateTRPCProxyClient, getUntypedClient } from '@trpc/client'
import type {
  AnyMutationProcedure,
  AnyProcedure,
  AnyQueryProcedure,
  AnyRouter,
  AnySubscriptionProcedure,
  inferProcedureInput,
  inferProcedureOutput,
  inferRouterInputs,
  inferRouterOutputs,
  Procedure,
  ProcedureType,
} from '@trpc/server'
import type { Unsubscribable } from '@trpc/server/observable'
import { createFlatProxy, createRecursiveProxy } from '@trpc/server/shared'
import {
  type Accessor,
  createEffect,
  createMemo,
  createResource,
  createSignal,
  onCleanup,
  onMount,
  type Resource,
  type ResourceReturn,
} from 'solid-js'
import { createStore } from 'solid-js/store'
import { isServer } from 'solid-js/web'

import type { Flatten } from '#/lambdaFunctions/utils/FlattenObject'

import { useTrpcContext } from './TrpcProvider'

type EmptyObject = {
  [key in never]?: never
}
export type UseQuery<
  TRouter extends AnyRouter,
  TQueryName extends keyof TRouter,
  ExtraArgs extends EmptyObject,
> = inferRouterInputs<TRouter>[TQueryName] extends void | undefined
  ? (
      opts?: UseProcedureArgs<inferRouterInputs<TRouter>[TQueryName]> &
        ExtraArgs,
    ) => ResourceReturn<inferRouterOutputs<TRouter>[TQueryName]>
  : (
      opts: UseProcedureArgs<inferRouterInputs<TRouter>[TQueryName]> &
        ExtraArgs,
    ) => ResourceReturn<inferRouterOutputs<TRouter>[TQueryName]>

type MutateFunction<
  TRouter extends AnyRouter,
  TMutationName extends keyof TRouter,
> = inferRouterInputs<TRouter>[TMutationName] extends void | undefined
  ? () => Promise<inferRouterOutputs<TRouter>[TMutationName]>
  : (
      input: inferRouterInputs<TRouter>[TMutationName],
    ) => Promise<inferRouterOutputs<TRouter>[TMutationName]>
type UseMutationy<
  TRouter extends AnyRouter,
  TMutationName extends keyof TRouter,
  ExtraArgs extends EmptyObject,
> = {
  (opts?: UseProcedureArgs<undefined> & ExtraArgs): [
    {
      mutate: MutateFunction<TRouter, TMutationName>
    } & {
      [key in TMutationName]: MutateFunction<TRouter, TMutationName>
    },
    Resource<inferRouterOutputs<TRouter>[TMutationName]>,
  ]
}
type UseSubscription<
  TRouter extends AnyRouter,
  TSubscriptionName extends keyof TRouter,
  ExtraArgs extends EmptyObject,
> = inferRouterInputs<TRouter>[TSubscriptionName] extends void | undefined
  ? (
      opts?: UseProcedureArgs<inferRouterInputs<TRouter>[TSubscriptionName]> &
        ExtraArgs,
    ) => [Accessor<inferRouterOutputs<TRouter>[TSubscriptionName]>]
  : (
      opts: UseProcedureArgs<inferRouterInputs<TRouter>[TSubscriptionName]> &
        ExtraArgs,
    ) => [Accessor<inferRouterOutputs<TRouter>[TSubscriptionName]>]
type OrNeverIf<A, B> = B extends void | undefined ? A | never : A
type UseProcedureArgs<Input> = OrNeverIf<
  ProcedureArgsOptions & ProcedureArgsInput<Input>,
  Input
>
type ProcedureArgsOptions = {
  fetchOnMount?: boolean
  ssr?: boolean
  skip?: Accessor<boolean>
  globalId?: string
}
type ProcedureArgsInput<Input> = Input extends void | undefined
  ? EmptyObject
  : { input: Input | (() => Input) }
type ProcedureArgs<
  ProcedureInfo extends FlattenedTrpcProcedureInfo<AnyRouter, TProcedureType>,
  TQueryName extends keyof ProcedureInfo,
  TProcedureType extends ProcedureType,
> = ProcedureArgsOptions &
  (TProcedureType extends 'mutation'
    ? EmptyObject
    : ProcedureArgsInput<ProcedureInfo[TQueryName]['input']>) & {
    [type in TProcedureType]: TQueryName
  }
type SolidTrpc<R extends AnyRouter, ExtraArgs extends EmptyObject> = {
  [K in keyof R['_def']['procedures']]: R['_def']['procedures'][K] extends AnyQueryProcedure
    ? { useQuery: UseQuery<R, K, ExtraArgs> }
    : R['_def']['procedures'][K] extends AnyMutationProcedure
      ? { useMutation: UseMutationy<R, K, ExtraArgs> }
      : R['_def']['procedures'][K] extends AnySubscriptionProcedure
        ? { useSubscription: UseSubscription<R, K, ExtraArgs> }
        : R['_def']['procedures'][K] extends AnyRouter
          ? SolidTrpc<R['_def']['procedures'][K], ExtraArgs>
          : never
}
type TrpcProcedureSignature<P extends AnyProcedure> = {
  type: P['_type']
  input: inferProcedureInput<P>
  output: inferProcedureOutput<P>
}
type TrpcProcedures<
  TRouter extends AnyRouter,
  TProcedureType extends ProcedureType,
> = {
  [K in keyof TRouter['_def']['procedures']]: TRouter['_def']['procedures'][K] extends Procedure<
    TProcedureType,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  >
    ? string
    : TrpcProcedures<TRouter['_def']['procedures'][K], TProcedureType>
}
type ResolveProcedureByPath<
  TRouter extends AnyRouter,
  TPath extends string,
> = TPath extends `${infer Head}.${infer Tail}`
  ? Head extends keyof TRouter['_def']['procedures']
    ? TRouter['_def']['procedures'][Head] extends AnyRouter
      ? ResolveProcedureByPath<TRouter['_def']['procedures'][Head], Tail> // Recurse if it's a nested router
      : TRouter['_def']['procedures'][Head] extends AnyProcedure
        ? TRouter['_def']['procedures'][Head]
        : never
    : never
  : TPath extends keyof TRouter['_def']['procedures']
    ? TRouter['_def']['procedures'][TPath] extends AnyProcedure
      ? TRouter['_def']['procedures'][TPath]
      : never
    : never
type FlattenedTrpcProcedures<
  R extends AnyRouter,
  TProcedureType extends ProcedureType,
> = Flatten<TrpcProcedures<R, TProcedureType>>
type FlattenedTrpcProcedureInfo<
  TRouter extends AnyRouter,
  TProcedureType extends ProcedureType,
> = {
  [key in keyof FlattenedTrpcProcedures<
    TRouter,
    TProcedureType
  > as ResolveProcedureByPath<TRouter, key> extends never
    ? never
    : key]: TrpcProcedureSignature<ResolveProcedureByPath<TRouter, key>>
}

export const createSolidTrpc = <
  TRouter extends AnyRouter,
  ExtraArgs extends Record<string, unknown> = Record<string, never>,
>(
  trpc: CreateTRPCProxyClient<TRouter>,
  {
    preprocess,
  }: {
    preprocess?: (
      args: ExtraArgs &
        (
          | ProcedureArgs<
              FlattenedTrpcProcedureInfo<TRouter, 'query'>,
              keyof FlattenedTrpcProcedureInfo<TRouter, 'query'>,
              'query'
            >
          | ProcedureArgs<
              FlattenedTrpcProcedureInfo<TRouter, 'mutation'>,
              keyof FlattenedTrpcProcedureInfo<TRouter, 'mutation'>,
              'mutation'
            >
          | ProcedureArgs<
              FlattenedTrpcProcedureInfo<TRouter, 'subscription'>,
              keyof FlattenedTrpcProcedureInfo<TRouter, 'subscription'>,
              'subscription'
            >
        ),
    ) => void | Promise<void>
  } & ExtraArgs,
) => {
  const untypedClient = getUntypedClient(trpc)

  type Queries = FlattenedTrpcProcedureInfo<TRouter, 'query'>
  type Mutations = FlattenedTrpcProcedureInfo<TRouter, 'mutation'>
  type Subscriptions = FlattenedTrpcProcedureInfo<TRouter, 'subscription'>
  type Procedures = Queries | Mutations | Subscriptions

  const createTrpcQuery = <TQueryName extends keyof Queries>(
    args: ExtraArgs & ProcedureArgs<Queries, TQueryName, 'query'>,
  ) => {
    type Query = Queries[TQueryName]

    const { query, fetchOnMount, ssr, skip } = args

    const input = 'input' in args ? args.input : undefined

    const inputFunction = typeof input === 'function' ? input : () => input

    const [resource, actions] = createResource(
      () =>
        [
          inputFunction(),
          Promise.resolve(preprocess?.(args) ?? undefined),
          skip?.(),
        ] as const,
      async ([input, preprocessResult, skip]) => {
        if (isServer && !ssr) return undefined
        try {
          await preprocessResult
        } catch {
          return undefined
        }

        if (skip) return undefined

        return untypedClient.query(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          query as any,
          input,
        ) as Promise<Query['output']>
      },
      {
        ssrLoadFrom: 'server',
        deferStream: true,
        ...(args.globalId && {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          storage: useTrpcContext().createStorage(args.globalId) as any,
        }),
      },
    )

    onMount(() => {
      if (fetchOnMount !== false) actions.refetch()
    })

    return [resource, actions] as const
  }

  const createTrpcMutation = <TMutationName extends keyof Mutations>(
    args: ExtraArgs & ProcedureArgs<Mutations, TMutationName, 'mutation'>,
  ) => {
    type Mutation = Mutations[TMutationName]

    const [resource, setResource] = createStore({
      latest: undefined as Mutation['output'] | undefined,
      data: undefined as Mutation['output'] | undefined,
      error: undefined as TRPCClientError<TRouter> | undefined,
      loading: false,
    })

    const mutationName = args.mutation

    const preprocessResult = createMemo(() => preprocess?.(args))

    const mutate = async (input: Mutation['input']) => {
      await preprocessResult()

      try {
        setResource({
          data: undefined,
          loading: true,
        })
        const newOutput = (await untypedClient.mutation(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          mutationName as any,
          input,
        )) as Mutation['output']

        setResource({
          data: newOutput,
          latest: newOutput,
          error: undefined,
          loading: false,
        })

        return newOutput
      } catch (e) {
        setResource({
          data: undefined,
          error:
            e instanceof TRPCClientError
              ? e
              : new TRPCClientError('Unknown error', {
                  cause: new Error(String(e)),
                }),
          loading: false,
        })

        throw e
      }
    }

    const lastPartOfMutationName = mutationName.toString().split('.').at(-1)!

    return [{ mutate, [lastPartOfMutationName]: mutate }, resource] as const
  }

  const createTrpcSubscription = <
    TSubscripotionName extends keyof Subscriptions,
  >(
    args: ExtraArgs &
      ProcedureArgs<Subscriptions, TSubscripotionName, 'subscription'>,
  ) => {
    type Subscription = Subscriptions[TSubscripotionName]

    const { subscription, ssr, skip, globalId } = args

    const input = 'input' in args ? args.input : undefined

    const inputFunction = typeof input === 'function' ? input : () => input

    let observable: Unsubscribable | undefined

    const [data, setData] = globalId
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (useTrpcContext().createStorage(globalId)() as any)
      : // eslint-disable-next-line solid/reactivity
        createSignal<Subscription['output']>()

    const startSubscription = async (
      input: Subscription['input'],
      preprocessResult: Promise<void>,
    ) => {
      observable?.unsubscribe()

      try {
        await preprocessResult

        observable = untypedClient.subscription(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          subscription as any,
          input,
          {
            onData: (data) => {
              setData(() => data as Subscription['output'])
            },
          },
        )
      } catch {
        setData(() => undefined)
        return undefined
      }
    }

    createEffect(() => {
      if (isServer && !ssr) return undefined
      if (skip) return undefined

      startSubscription(inputFunction(), Promise.resolve(preprocess?.(args)))
    })

    onCleanup(() => {
      observable?.unsubscribe()
    })

    return [data] as const
  }

  const solidTrpc: SolidTrpc<TRouter, ExtraArgs> = createFlatProxy((key) => {
    return createRecursiveProxy(({ path, args }) => {
      const pathCopy = [key, ...path]
      const lastbit = pathCopy.pop()
      const procedureType = lastbit!.toLowerCase().slice(3)

      const fullPath = pathCopy.join('.')

      const procedureArgs: ExtraArgs &
        ProcedureArgs<Procedures, keyof Procedures, ProcedureType> = {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(args[0] as any),
        [procedureType]: fullPath,
      }

      if ('mutation' in procedureArgs) {
        return createTrpcMutation(procedureArgs)
      } else if ('subscription' in procedureArgs) {
        return createTrpcSubscription(procedureArgs)
      } else {
        return createTrpcQuery(procedureArgs)
      }
    })
  })

  return { solidTrpc, createTrpcMutation, createTrpcQuery }
}
