import type { ZodRawShape } from 'zod'
import { z } from 'zod'

import { token } from '../config'

export const createJwtTokenPayloadSchema = <
  Audience extends string,
  Schema extends ZodRawShape,
>(
  audience: Audience,
  schema: Schema,
) => {
  const baseSchema = z
    .object({
      sub: z.string().min(1),
      exp: z.number().transform((timestamp) => new Date(timestamp * 1000)),
      iat: z.number().transform((timestamp) => new Date(timestamp * 1000)),
      iss: z.literal(token.issuer),
      aud: z.literal(audience),
    })
    .transform((data) => {
      const {
        exp: expiresAt,
        iat: issuedAt,
        sub: userId,
        iss: issuer,
        aud: audience,
        ...rest
      } = data

      return { expiresAt, issuedAt, userId, issuer, audience, ...rest }
    })

  const extendedSchema = z.object(schema)

  return z.any().transform((data) => {
    return { ...extendedSchema.parse(data), ...baseSchema.parse(data) }
  })
}
