import type { StripeElements } from '@stripe/stripe-js'
import type { inferProcedureOutput } from '@trpc/server'

import type { AppRouter } from '#/trpc/server'

import { getProvider } from './getProvider'

type ProviderState =
  | {
      provider: 'stripe'
      subscriptionId: string
      clientSecret: string
      elements?: StripeElements
    }
  | {
      provider: 'apple'
      appAccountToken: string
      transactionId?: string
    }
  | {
      provider: 'google'
      obfuscatedAccountId: string
      transactionId?: string
    }

type KeysOfUnion<T> = T extends T ? keyof T : never

export type InitialSubscriptionState = typeof initialSubscriptionState &
  (
    | ProviderState
    | (Pick<ProviderState, 'provider'> & {
        [key in Exclude<KeysOfUnion<ProviderState>, 'provider'>]?: never
      })
  )

export const initialSubscriptionState = {
  isMutationLoading: false,
  isSubscriptionLoading: true,
  isDialogOpen: false,
  subscription: undefined as
    | undefined
    | inferProcedureOutput<AppRouter['getSubscriptionStatus']>,
  selectedPlanId: undefined as string | undefined,
  provider: getProvider(),
}
