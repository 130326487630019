import cookie from 'js-cookie'

import { accessTokenCookie, authCookiePaths } from '../../config'
import type { TokenStore } from './createTokenStore'

export function createResetState(setTokenState: TokenStore[1]) {
  return () => {
    authCookiePaths.forEach((path) => {
      cookie.remove(accessTokenCookie, {
        domain: new URL(location.origin).hostname,
        path,
      })
    })

    setTokenState({
      accessToken: undefined,
      expiresAt: undefined,
      issuedAt: undefined,
      isLoading: false,
      hasFinishedInitialLoading: true,
    })
  }
}
