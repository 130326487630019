import { createTRPCProxyClient, httpLink } from '@trpc/client'
import SuperJSON from 'superjson'

import { getTrpcHeaders } from '#/trpc/getTrpcHeaders'

import type { AuthRouter } from './authServer'

export const authClient = createTRPCProxyClient<AuthRouter>({
  links: [
    httpLink({
      url: '/api/auth',
      headers: getTrpcHeaders(),
    }),
  ],
  transformer: SuperJSON,
})
