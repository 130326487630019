import * as i18n from '@solid-primitives/i18n'

import type { Language } from './languages'
import type englishTranslations from './translations/en'

export type Translations = typeof englishTranslations
export type Dictionary = i18n.Flatten<Translations>

export async function fetchDictionary(language: Language): Promise<Dictionary> {
  const dict: Translations = (await import(`./translations/${language}.ts`))
    .default

  return i18n.flatten(dict)
}
