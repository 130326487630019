import { Dialog as KobalteDialog } from '@kobalte/core/dialog'
import type { JSX } from 'solid-js'
import { createMemo, createRoot, createSignal, Show } from 'solid-js'
import type { SetStoreFunction } from 'solid-js/store'

import { Spinner } from '#/components/ui/Spinner'
import { createModule } from '#/utils/module/createModule'

import styles from './Dialog.module.css'

type DialogState = ReturnType<ReturnType<typeof mapState>>

type InitialState = typeof initialState

const initialState = {
  isLoading: false,
  title: undefined as JSX.Element | undefined,
  content: undefined as JSX.Element | undefined,
}

const mapState = (state: InitialState) => {
  // eslint-disable-next-line solid/reactivity
  return createMemo(() => ({
    ...state,
    isOpen: !!state.content,
  }))
}

const createActions = (
  state: DialogState,
  setState: SetStoreFunction<InitialState>,
) => {
  const [dispose, setDispose] = createSignal<() => void>(() => {})
  const open = (
    getRoot: (setIsLoading: (isLoading: boolean) => void) => {
      title?: JSX.Element
      content: JSX.Element
    },
  ) => {
    const { dispose, ...root } = createRoot((dispose) => ({
      dispose,
      ...getRoot((isLoading: boolean) => setState({ isLoading })),
    }))

    setState(root)
    setDispose(() => dispose)
  }
  const close = () => {
    dispose()
    setState({
      content: null,
      title: null,
      isLoading: false,
    })
  }

  return { open, close }
}

const {
  ContextProvider,
  useActions: useDialog,
  useState: useDialogState,
} = createModule({
  initialState,
  createActions,
  mapState,
  log: console.debug,
  name: 'dialog',
})

const Dialog = (props: {
  isOpen: boolean
  isLoading: boolean
  onOpenChange?: (isOpen: boolean) => void
  title?: JSX.Element
  children?: JSX.Element
}) => {
  return (
    <KobalteDialog
      open={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      <KobalteDialog.Portal>
        <KobalteDialog.Overlay
          classList={{
            [styles.DialogOverlay!]: true,
          }}
        />
        <div
          classList={{
            [styles.DialogPositioner!]: props.isOpen,
          }}
        >
          <Show when={props.isOpen}>
            <KobalteDialog.Content class={styles.DialogContent}>
              <Show when={props.title}>
                <KobalteDialog.Title class={styles.DialogTitle}>
                  {props.title}
                </KobalteDialog.Title>
              </Show>

              <KobalteDialog.Description class={styles.DialogDescription}>
                {props.children}
              </KobalteDialog.Description>
            </KobalteDialog.Content>
          </Show>
          <Show when={props.isLoading}>
            <Spinner centered />
          </Show>
        </div>
      </KobalteDialog.Portal>
    </KobalteDialog>
  )
}

const ContextDialog = () => {
  const actions = useDialog()
  const state = useDialogState()

  return (
    <Dialog
      isLoading={state.isLoading}
      isOpen={state.isOpen}
      onOpenChange={() => actions.close()}
      title={state.title}
    >
      {state.content}
    </Dialog>
  )
}

const DialogProvider = (props: { children: JSX.Element }) => (
  <ContextProvider>
    {props.children}
    <ContextDialog />
  </ContextProvider>
)

export { Dialog, DialogProvider, useDialog }
