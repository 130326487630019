import type { OverrideComponentProps } from '@kobalte/core'
import { Polymorphic } from '@kobalte/core'
import type { ValidComponent } from 'solid-js'
import { createMemo, mergeProps, splitProps } from 'solid-js'

import styles from './Flex.module.css'

export type FlexProps<As extends ValidComponent = 'div'> =
  OverrideComponentProps<
    As,
    {
      direction?: 'row' | 'column'
      flex?: number | true
      fullScreen?: boolean
      justifyContent?: 'start' | 'end' | 'center' | 'space-between'
      alignItems?: 'start' | 'end' | 'center'
      fill?: true
      gap?: 'none' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
      margin?: 'none' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
      safeAreaPadding?: boolean
    }
  >

const Flex = <As extends ValidComponent>(props: FlexProps<As>) => {
  const propsWithDefaults = mergeProps({ direction: 'row', flex: 0 }, props)
  const [ownProps, rest] = splitProps(propsWithDefaults, [
    'direction',
    'flex',
    'fullScreen',
    'justifyContent',
    'alignItems',
    'fill',
    'gap',
    'margin',
    'safeAreaPadding',
  ])

  const flex = createMemo(() => (ownProps.flex === true ? 1 : ownProps.flex))

  const hasFlexClass = createMemo(() => flex() > 0 && flex() <= 3)

  const style = createMemo(() => ({
    ...(flex() && !hasFlexClass() ? { flex: flex() } : {}),
    ...props.style,
  }))

  return (
    <Polymorphic
      as={'div'}
      data-direction={ownProps.direction}
      data-fullscreen={ownProps.fullScreen}
      data-flex={flex() ? flex() : undefined}
      data-align-items={ownProps.alignItems}
      data-justify-content={ownProps.justifyContent}
      data-fill={ownProps.fill ? '' : undefined}
      data-gap={ownProps.gap}
      data-margin={ownProps.margin}
      data-safe-area-padding={ownProps.safeAreaPadding ? '' : undefined}
      {...rest}
      style={style()}
      classList={{
        ...props.classList,
        ...(props.class ? { [props.class]: true } : {}),
        [styles.Flex!]: true,
      }}
    />
  )
}

export { Flex }
